import React from 'react'
import SEO from '../components/seo'
import Header from '../components/Header'
import Footer from '../components/footer'
import Layout from '../components/Layout'
import {Container, Row, Col} from "react-bootstrap"
import ConsentForm from '../components/consentForm'
import { graphql } from 'gatsby'

const ReleaseForm = ({data}) => {
    const { markdownRemark } = data
    const {frontmatter, html} = markdownRemark

    return (
        <Layout>
            <SEO title="RadNet Voluntary Photo Consent | " />
            <div className="mt4">
                <Header fluid={false} pageName="Voluntary Photo / Release Form"/>
                <Container>
                    <Row>
                        <Col>
                            <div className="content" dangerouslySetInnerHTML={{__html:html}}>
                            </div>
                        </Col>
                    </Row>
                    <Row className="pb5">
                        <Col>
                            <ConsentForm />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </Layout>
    )
}

export default ReleaseForm

export const RFpageQuery = graphql`
    query($slug: String!){
        markdownRemark(frontmatter: { slug: { eq: $slug}}){
            html 
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                slug
                title
            }
        }
    }
`
