import React, {useEffect, useState, useRef} from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import SignaturePad from 'signature_pad'

const ConsentForm = () => {
    //do some stuff
    //update props? re-render?
    const [sending, setSending] = useState(false);
    const [selectType, setSelectType] = useState(""); 
    const [sent, setSent] = useState(false);
    const consentRef = useRef();
    const fullnameRef = useRef();
    const canvasRef = useRef();
    const employerRef = useRef();
    const identityRef = useRef();

    useEffect(() => {
        if (employerRef.current) { // Add null check here
            employerRef.current.value = ""; // Reset the value of the txtEmployer input when selectType changes
        }
    }, [selectType]);

    useEffect(()=>{
        if (window.document.documentMode) {
            // Do IE stuff
            window.location.href = "/not-supported"
        }
        let canvas = document.querySelector("canvas");
        var ratio =  Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
        let signaturePad = new SignaturePad(canvas, {
                minDistance: 1
        });
        signaturePad.onBegin = ()=> {
            document.getElementById("signature-pad").style.border ="1px solid #ced4da";
        }
        document.getElementById("clear").addEventListener('click', ()=>{
            //console.log('click')
            signaturePad.clear()
        })
        let hasimage = "false";
        let chkImage = document.getElementById("chkImage");
        let collapseOne = document.getElementById("collapseOne");
        chkImage.addEventListener('change', ()=>{
            if(chkImage.checked){
                collapseOne.classList.add("show");
                hasimage = "true";
            }else{
                collapseOne.classList.remove("show");
                hasimage = "false";
            }
        })
        let allowedExtension = ['image/jpeg', 'image/jpg', 'image/png'];
        let txtfile = document.getElementById("txtfile");
        let imgpreview = document.getElementById("imgpreview");
        txtfile.onchange = evt => {
            if(document.getElementById('txtfile').value){
                let type = document.getElementById('txtfile').files[0].type;
                if(allowedExtension.indexOf(type) < 0){
                    alert('Type of file not alllowed. Please choose a .jpeg or .png image');
                    document.getElementById('txtfile').value = null;
                    imgpreview.src = ""
                    return false;
                }
                const [file] = txtfile.files
                if (file) {
                    imgpreview.src = URL.createObjectURL(file)
                }
            }else{
                imgpreview.src = ""
            }
        }

        document.getElementById("submit").addEventListener('click',(e)=>{

            e.preventDefault();
            let formData = new FormData(document.querySelector('form'))
            let signature = signaturePad.toDataURL() 

            //Generate image preview and encode
            var imagencoded = ""
            if(document.getElementById('txtfile').value){
                let type = document.getElementById('txtfile').files[0].type;
                if(allowedExtension.indexOf(type) < 0){
                    alert('Type of file not alllowed. Please choose a .jpeg or .png image');
                    document.getElementById('txtfile').value = null;
                    imgpreview.src = ""
                    return false;
                }else{
                    var canvasImg = document.createElement("canvas")
                    canvasImg.width = imgpreview.width
                    canvasImg.height = imgpreview.height
                    var ctx = canvasImg.getContext("2d")
                    ctx.drawImage(imgpreview, 0, 0)
                    imagencoded = canvasImg.toDataURL()
                }
            }else{
                if(document.getElementById('chkImage').checked){
                    alert("Please attach a valid image");
                    return false;
                }
            }
            //END

            let name = formData.get("fullname")
            let witness = formData.get("witness")
            let consent = formData.get("consent")
            let source = formData.get("source")
            let imagedesc = formData.get("txtimgdesc")
            let employer = formData.get("txtEmployer")
            let identity = formData.get("selectType")


            consentRef.current.classList.remove("is-invalid");
            fullnameRef.current.classList.remove("is-invalid");
            identityRef.current.classList.remove("is-invalid");
            if (employerRef.current) {
                employerRef.current.classList.remove("is-invalid");
            }
            document.getElementById("signature-pad").style.border = "1px solid #ced4da";

            if(!name || !consent || !identity || signaturePad.isEmpty()){
                if (!consent){ consentRef.current.classList.add("is-invalid"); }
                if (!name){ fullnameRef.current.classList.add("is-invalid"); }
                if (!name){ fullnameRef.current.classList.add("is-invalid"); }
                if (!employer && employerRef.current) { 
                    employerRef.current.classList.add("is-invalid"); 
                }
                if (!identity){ identityRef.current.classList.add("is-invalid"); }
                if(signaturePad.isEmpty()){ document.getElementById("signature-pad").style.border = "1px solid red"; } 
                return
            }

            setSending(true)

            /*let res = JSON.stringify({
                "name": name,
                "witness": witness,
                "consent": consent,
                "type": "Consent",
                "signature": signature,
                "image": imagencoded,
                "imagedesc": imagedesc,
                "hasimage": hasimage,
                "source": "consent"
            })*/
            let res  = new FormData();
            res.append('name', name);
            res.append('witness', witness);
            res.append('consent', consent);
            res.append('signature', signature);
            res.append('image', imagencoded);
            res.append('imagedesc', imagedesc);
            res.append('employer', employer);
            res.append('identity', identity);
            res.append('hasimage', hasimage);
            res.append('source', "consent");
            console.log(res)
            let url = "https://api.rcg.ninja/consent-form/"
                    //let url = "https://sqjdop438l.execute-api.us-east-2.amazonaws.com/consent"
                    //let url = "https://70s1asfku2.execute-api.us-east-2.amazonaws.com/default/consentFormTest"
            fetch(url,{
                method: 'POST',
                mode:'no-cors',
                headers: {
                    "Content-Type":'application/json'
                },
                body: res
            }).then(res => res.text).then(res =>{
                //console.log(res)
                setSent(true);
                //document.querySelector("#messages").innerText = "Message sent successfully"
                
            }).catch(err => {
                //console.log(err)
                //document.querySelector("#messages").innerText = ""
            })
        })

    }, []);

    //send form data to controller, save data and email
    return (
        <>
        
        <form className={`${!sending ? '':'d-none'}`} action="#" method="POST" onSubmit={()=>false}>
            <Container>
                <Row>
                    <Col className="pl-4 pt-4 pb-4">
                        <input name="consent" required ref={consentRef} onChange={() => {consentRef.current.classList.remove("is-invalid")}} className="form-check-input" id="consent" type="checkbox"  />
                        <label className="form-check-label" htmlFor="consent" >I am at least 18 years of age and am competent to contract in my own name. I have read this release before signing below and I fully understand the contents, meaning and impact of this release.</label>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label htmlFor="fullname">Full Name:</label>
                        <input className="form-control" required type="text" onChange={()=>{fullnameRef.current.classList.remove("is-invalid")}} ref={fullnameRef} name="fullname" id="fullname" placeholder="Enter your full name *" />
                        <sub>* Denotes Required</sub>
                    </Col>
                    <Col>
                        <label htmlFor="witness" className="witness">Witness Name:</label>
                        <input className="form-control" type="text" name="witness" id="witness" placeholder="Witness Name" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <label htmlFor="selectType" className="selectType">Identity:</label>
                        <select className="form-control" required ref={identityRef} name="selectType" id="selectType" onChange={(e) => {
                            setSelectType(e.target.value);
                            identityRef.current.classList.remove("is-invalid");
                        }}>
                            <option selected disabled>Select an option:*</option>
                            <option value="Employee">Employee</option>
                            <option value="Patient">Patient</option>
                            <option value="Visitor">Visitor</option>
                            <option value="Guest">Guest</option>
                        </select>
                    </Col>
                    {selectType !== "" && selectType !== "Patient" && (
                        <Col>
                            <label htmlFor="txtEmployer">Employer:</label>
                            <input className="form-control" required type="text" onChange={()=>{employerRef.current.classList.remove("is-invalid")}} ref={employerRef} name="txtEmployer" id="txtEmployer" placeholder="Employer" />
                        </Col>
                    )}
                </Row>
                <br/>
                <div id="accordion">
                    <div className="card">
                        <div className="card-header" id="headingOne">
                            <h6 className="mb-0">
                                <input type="checkbox" id="chkImage" /> &nbsp;
                                <label className="chkImage" htmlFor="chkImage">
                                Attach an image <small>(Optional)</small> 
                                </label>
                            </h6>
                        </div>
                        <div id="collapseOne" className="collapse">
                            <div className="card-body">
                                <Row>
                                    <Col>
                                        <label htmlFor=""></label>
                                        <input
                                            type="file"
                                            className="form-control-file" 
                                            name="txtfile" id="txtfile" accept="image/*"
                                        />
                                    </Col>
                                    <Col>
                                        <label htmlFor=""></label>
                                        <textarea className="form-control" name="txtimgdesc" id="txtimgdesc" placeholder="Image description"></textarea>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <br/>
                                        <h6>Image preview</h6>
                                        <img id="imgpreview" src=""/>
                                        <br/>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
            <Row>
                <Col>
                <p className="mt4">Your signature *</p>
                <canvas ref={canvasRef}  style={{border:`1px solid #ced4da`, borderRadius:`0.25rem`, height: `200px`, width:`100%`}} id="signature-pad" className="signature-pad"></canvas>

                </Col>
            </Row>
            <Row>
                <Col className="mt-4">
                <input type="hidden" name="source" id="source" value="consent" />
                <button type="submit" id="submit" className="btn btn-dark mr-1">Submit</button>
                <button type="button" className="btn btn-outline-dark" id="clear">Clear</button>
                </Col>
            </Row>
        </form>
        <div className={`${!sending ? 'd-none':''}`}>
            Thanks for submitting!
            <p id="message"></p>
        </div>
        </>
    )
}

export default ConsentForm